import { richText } from '../../utils/html';

export default async ({ primary }, pageData, { $prismic }) => {
  const table = await $prismic.client.getByUID('table', primary.table?.uid);
  const { title, description, row_axis_header, col_axis_header, body } =
    table.data || {};
  const sliceRows = body.map(({ items }) => items);
  return {
    id: primary.table?.uid,
    title: richText(title),
    description: richText(description),
    rowAxisHeader: row_axis_header,
    colAxisHeader: col_axis_header,
    rows:
      sliceRows?.map((row) =>
        row?.map(({ cell_content }) => richText(cell_content))
      ) || [],
  };
};
